import { Flex, Text } from "@chakra-ui/react";

interface Props {
  title: string;
  subTitle: string;
  icon: JSX.Element;
  primaryColor: string;
  secondaryColor: string;
  onClick(): void;
}

export function QuickAccessCard({
  icon,
  primaryColor,
  secondaryColor,
  subTitle,
  title,
  onClick,
}: Props) {
  return (
    <Flex
      onClick={onClick}
      as="button"
      w="350px"
      h="160px"
      borderRadius={12}
      borderWidth="0.5px"
      borderColor={secondaryColor}
      alignItems="center"
    >
      <Flex
        ml="22px"
        alignItems="center"
        justifyContent="center"
        w="72px"
        h="72px"
        borderRadius={12}
        bgColor={secondaryColor}
      >
        {icon}
      </Flex>

      <Flex ml="20px" flexDir="column" flex={1}>
        <Text
          textAlign="start"
          color={primaryColor}
          fontSize="24px"
          fontWeight={600}
        >
          {title}
        </Text>
        <Text
          textAlign="start"
          color={primaryColor}
          fontSize="16px"
          fontWeight={400}
        >
          {subTitle}
        </Text>
      </Flex>
    </Flex>
  );
}
