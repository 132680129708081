import { Flex, Text } from '@chakra-ui/react';

interface ProfileAreaProps {
    name?: string;
    cro?: string;
}
export function ProfileArea({ name, cro }: ProfileAreaProps) {
    return (
        <Flex flexDir="column" justifyContent="end">
            <Text color="rgba(95, 92, 107, 1)" fontSize="14px" fontWeight={500}>
                Dr. {name}
            </Text>
            <Text color="rgba(95, 92, 107, 1)" fontSize="12px" fontWeight={400}>
                CRO {cro}
            </Text>
        </Flex>
    );
}
