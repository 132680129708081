import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '../../service/api';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/react';
import { FilterModel } from '../../models/filter.model';
import { useEffect } from 'react';
import { CreateMedicationModel, GetProcedureModel } from '../../models/medication.model';

async function createPrescription(patient: CreateMedicationModel): Promise<AxiosResponse> {
    return api.post('/prescription', patient);
}

async function getPrescription(params: any): Promise<AxiosResponse> {
    return api.get('/prescription', { params });
}

async function getProcedureOptions(params: any): Promise<AxiosResponse> {
    return api.get<GetProcedureModel>('/procedure-options', { params });
}

export function usePrescription() {
    const toast = useToast();
    const createPrescriptionMutation = useMutation<AxiosResponse, unknown, CreateMedicationModel, unknown>({
        mutationFn: createPrescription,
        onSuccess: () => {
            toast({
                title: 'Prescrição criada com sucesso!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.message ? error.response.data.message : 'Erro ao criar prescrição',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return createPrescriptionMutation;
}

export function useGetPrescription(filters?: FilterModel[]) {
    let params = {};
    if (filters) {
        filters.map((f: any) => {
            let fil: any = {};
            fil[f.field] = f.filter;
            params = { ...params, ...fil };
            return null;
        });
    }
    const toast = useToast();
    const getPrescriptionMutation = useQuery({
        queryFn: () => getPrescription(params),
        queryKey: ['prescriptions', params],
    });

    useEffect(() => {
        if (getPrescriptionMutation.isError) {
            toast({
                title: 'Erro ao buscar prescrições',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [getPrescriptionMutation.isError, toast]);

    return getPrescriptionMutation;
}

export function useGetProcedureOptions(filters?: FilterModel[]) {
    const toast = useToast();
    const getProcedureOptionsMutation = useQuery<AxiosResponse>({
        queryFn: () => getProcedureOptions(filters),
        queryKey: ['procedureOptions', filters],
    });

    useEffect(() => {
        if (getProcedureOptionsMutation.isError) {
            toast({
                title: 'Erro ao buscar opções de procedimentos',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [getProcedureOptionsMutation.isError, toast]);

    return getProcedureOptionsMutation;
}
