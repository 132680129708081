import { GetPainGraphModel } from './../../models/painDiary.model';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../service/api';

async function getGraphicByUser(patientId: string): Promise<GetPainGraphModel[]> {
    try {
        const data = await api.get<GetPainGraphModel[]>(`/pain-diary/graphic/${patientId}`);
        return data.data;
    } catch (error) {
        throw error;
    }
}

export function useGetGraphicByUser(patientId: string) {
    return useQuery<GetPainGraphModel[], unknown>({
        queryKey: ['pain-diary-graphic', patientId],

        queryFn: async () => getGraphicByUser(patientId),

        throwOnError: true,

        enabled: !!patientId,
    });
}
