import { Route } from 'react-router-dom';
import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { LoginPage } from '../pages/LoginPage';
import { SignUpPage } from '../pages/SignUpPage';
import { HomePage } from '../pages/HomePage';
import { CadastrarPacientePage } from '../pages/CadastrarPacientePage';
import { ChartsPage } from '../pages/ChartsPage';
import { PacientesPage } from '../pages//PacientesPage';
import { ProtectedLayout } from '../layouts/ProtectedLayout';
import { StartLayout } from '../layouts/StartLayout';
import { CadastrarPrescricaoPage } from '../pages/CadastrarPrescricaoPage';
import { PatientDetailsPage } from '../pages/PatientDetailsPage';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<StartLayout />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
            </Route>

            <Route element={<ProtectedLayout />}>
                <Route path="/home" element={<HomePage />} />
                <Route path="/charts" element={<ChartsPage />} />
                <Route path="/pacientes" element={<PacientesPage />} />
                <Route path="/pacientes/cadastrar" element={<CadastrarPacientePage />} />
                <Route path="/pacientes/detalhes" element={<PatientDetailsPage />} />
                <Route path="/prescricoes/cadastrar" element={<CadastrarPrescricaoPage />} />
            </Route>
        </>
    )
);
