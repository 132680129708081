import { Box, Flex, Select, Text } from '@chakra-ui/react';
import { PainChart } from './PainChart';
import { PainScale } from './PainScale';
import { useGetGraphicByUser } from '../hook/api/usePainDiary';
import { useGetPatients } from '../hook/api/usePatient';
import { useState } from 'react';
import { FilterModel } from '../models/filter.model';
import { useAuth } from '../context/AuthContext';

export function PainChartContainer() {
    const [patientId, setPatientId] = useState<string>('');
    const { userData } = useAuth!();
    const filter: FilterModel[] = [
        {
            name: 'dentistId',
            field: 'dentistId',
            filter: userData?.id!,
        },
    ];
    const getPatientsMutation = useGetPatients(filter);
    const getGraphicByUserMutation = useGetGraphicByUser(patientId);

    const changePatient = (event: any) => {
        if (event.target.value === '') {
            setPatientId('');
            return;
        }
        setPatientId(event.target.value);
        // getGraphicByUserMutation.refetch(event.target.value);
    };

    console.log(getGraphicByUserMutation.data);

    return (
        <Flex
            borderWidth="0.5px"
            borderColor="#E2E8F0"
            borderRadius={16}
            css={{ boxShadow: '0px 1px 2px 0.5px rgba(78, 75, 89, 0.10)' }}
            p="30px 30px 30px 0px"
            h="100%"
            flexDir="column"
        >
            <Box ml="40px">
                <Flex justifyContent="space-between">
                    <Text color="#4E4B59" fontWeight={400} fontSize={30}>
                        Visão geral
                    </Text>

                    <Select id="patientName" placeholder="Paciente" bgColor="#fff" width="50%" onChange={changePatient}>
                        {getPatientsMutation.data?.data.map((patient: any) => (
                            <option key={patient.id} value={patient.id}>
                                {patient.name}
                            </option>
                        ))}
                    </Select>
                </Flex>
            </Box>

            <Flex justifyContent="space-between" mt="44px" h="100%" flexDir="row">
                <Flex w="880px" h="100%">
                    <PainChart data={getGraphicByUserMutation.data} active={patientId.length > 0} />
                </Flex>
                <PainScale />
            </Flex>
        </Flex>
    );
}
