import { Flex, Box, Image } from '@chakra-ui/react';
import { FiGrid, FiUserPlus, FiUsers, FiBarChart, FiLogOut, FiFilePlus } from 'react-icons/fi';
import { SideNavItem } from './SideNavItem';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/svg/logo.svg';

interface props {
    logout: () => void;
}

export function SideNav({ logout }: props) {
    let { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <Flex
            flexDir="column"
            minWidth="256px"
            padding="24px 0px 24px 24px"
            borderRightWidth="1px"
            borderColor="#E1E0E7"
            paddingTop="15px"
            h="100vh"
            justifyContent="space-between"
        >
            <Flex flexDir="column" marginBottom="48px" gap="48px" w="100%">
                <Image src={Logo} alt="Logo" />
                <SideNavItem
                    customIcon={FiGrid}
                    title="Início"
                    isClicked={pathname === '/home'}
                    onClick={() => {
                        navigate('/home');
                    }}
                />
                <SideNavItem
                    customIcon={FiUserPlus}
                    title="Cadastrar paciente"
                    isClicked={pathname === '/pacientes/cadastrar'}
                    onClick={() => {
                        navigate('/pacientes/cadastrar');
                    }}
                />
                <SideNavItem
                    customIcon={FiUsers}
                    title="Meus pacientes"
                    isClicked={pathname === '/pacientes'}
                    onClick={() => {
                        navigate('/pacientes');
                    }}
                />
                <SideNavItem
                    customIcon={FiFilePlus}
                    title="Cadastrar prescrição"
                    isClicked={pathname === '/prescricoes/cadastrar'}
                    onClick={() => {
                        navigate('/prescricoes/cadastrar');
                    }}
                />
                <SideNavItem
                    customIcon={FiBarChart}
                    title="Gráficos"
                    isClicked={pathname === '/charts'}
                    onClick={() => {
                        navigate('/charts');
                    }}
                />
            </Flex>
            <Box marginRight="24px" paddingTop="28px" borderTopWidth="1px" borderColor="#E1E0E7">
                <SideNavItem customIcon={FiLogOut} title="Desconectar" onClick={logout} />
            </Box>
        </Flex>
    );
}
