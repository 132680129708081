import { useMutation } from '@tanstack/react-query';
import { api } from '../../service/api';
import { SignUpFormModel, UserModel } from '../../models/user.model';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/react';
import { FilterModel } from '../../models/filter.model';
import { TokenModel } from '../../models/login.model';
import { useAuth } from '../../context/AuthContext';

async function getUser(params: any): Promise<AxiosResponse> {
    return api.get<UserModel>('/user', { params });
}

async function createUser(user: SignUpFormModel): Promise<AxiosResponse> {
    return api.post('/user', user);
}

export function useGetUser(filters?: FilterModel[]) {
    let params = {};
    if (filters) {
        filters.map((f: any) => {
            let fil: any = {};
            fil[f.field] = f.filter;
            params = { ...params, ...fil };
            return null; // Add a return statement here
        });
    }
    const toast = useToast();
    const getUserMutation = useMutation<AxiosResponse, unknown, UserModel, unknown>({
        mutationFn: getUser,
        onError: (error: any) => {
            toast({
                title: error.response.data.message ? error.response.data.message : 'Erro ao buscar usuário',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return getUserMutation;
}

export function useCreateUser() {
    const { setToken } = useAuth();
    const toast = useToast();
    console.log('useCreateUser');
    const createUserMutation = useMutation<AxiosResponse, unknown, SignUpFormModel, unknown>({
        mutationFn: createUser,
        onSuccess: (response: AxiosResponse) => {
            console.log(response);
            const data: TokenModel = response.data;
            toast({
                title: 'Usuário criado com sucesso!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
            console.log(data.token);
            setToken(data.token);
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.message ? error.response.data.message : 'Erro ao criar usuário',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return createUserMutation;
}
