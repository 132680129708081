import { Box, Flex, Text } from '@chakra-ui/react';

const scaleOptions = [
    {
        label: 'Nível 1',
        color: '#8FE5F3',
    },
    {
        label: 'Nível 2',
        color: '#16EAC2',
    },
    {
        label: 'Nível 3',
        color: '#90D202',
    },
    {
        label: 'Nível 4',
        color: '#D2E801',
    },
    {
        label: 'Nível 5',
        color: '#FFF134',
    },
    {
        label: 'Nível 6',
        color: '#FFC71E',
    },
    {
        label: 'Nível 7',
        color: '#FB9A27',
    },
    {
        label: 'Nível 8',
        color: '#FF8563',
    },
    {
        label: 'Nível 9',
        color: '#FE4432',
    },
    {
        label: 'Nível 10',
        color: '#FC0D00',
    },
];

export function PainScale() {
    return (
        <Flex p="12px" flexDir="column" w="120px" h="264px" bgColor="#F7FAFC">
            <Text fontWeight={600} fontSize={14}>
                Escala de dor
            </Text>
            <Flex mt="8px" flexDir="column" gap="4px">
                {scaleOptions.map(option => {
                    return (
                        <Flex key={option.label} flexDir="row">
                            <Box w="12px" h="12px" bgColor={option.color} />
                            <Text ml="8px" fontWeight={500} fontSize={12}>
                                {option.label}
                            </Text>
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
}
