import { GetUserModel } from '../../models/user.model';
import { FilterModel } from '../../models/filter.model';
import { api } from '../api';

export const userService = {
    getUsers: async (filters: FilterModel[]): Promise<GetUserModel[]> => {
        let params = {};
        if (filters) {
            filters.map((f: any) => {
                let fil: any = {};
                fil[f.field] = f.filter;
                params = { ...params, ...fil };
                return null;
            });
        }
        const response = await api.get<GetUserModel[]>('/user', { params });
        return response.data;
    },
};
