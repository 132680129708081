import { Flex, Select, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { PatientModel } from '../models/patient.model';
import { useCreatePatient } from '../hook/api/usePatient';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useAuth } from '../context/AuthContext';

export function CadastrarPacientePage() {
    const { userData } = useAuth();
    const { handleSubmit, register, control } = useForm<PatientModel>();
    const { mutate, isSuccess } = useCreatePatient();
    const navigate = useNavigate();

    const formatNumber = (value: string) => {
        let formattedValue = value.replace(/[^0-9]/g, '');
        if (formattedValue.length > 2) {
            formattedValue = formattedValue.slice(0, -2) + '.' + formattedValue.slice(-2);
        }
        return formattedValue;
    };

    async function handleCreatePatient(values: PatientModel) {
        if (!userData) return;
        values.userId = userData.id;
        mutate(values);
    }
    if (isSuccess) {
        navigate('/pacientes');
    }

    return (
        <Flex p="0px 44px" pb="10px" flexDir="column">
            <form onSubmit={handleSubmit(handleCreatePatient)}>
                <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600}>
                    Cadastrar paciente
                </Text>
                <Flex
                    mt="24px"
                    borderWidth="0.5px"
                    borderColor="#E2E8F0"
                    borderRadius={16}
                    css={{ boxShadow: '0px 1px 2px 0.5px rgba(78, 75, 89, 0.10)' }}
                    p="32px"
                    maxWidth="1200px"
                    h="530px"
                    w="100%"
                    flexDir="column"
                >
                    <Flex flexDir="column">
                        <Text color="#2C7A7B" fontSize="20px" fontWeight={600}>
                            Dados Pessoais
                        </Text>
                        <Text color="#8C8A97" fontSize="14px" fontWeight={400}>
                            Insira nos campos abaixo os dados do paciente
                        </Text>
                    </Flex>
                    <Flex mt="44px" gap="40px" flexDir="column">
                        <Flex>
                            <Input
                                mr="44px"
                                id="fullName"
                                placeholder="Nome completo"
                                {...register('name', {
                                    required: 'This is required',
                                    minLength: { value: 4, message: 'Minimum length should be 4' },
                                })}
                            />

                            <Flex w={'100%'}>
                                <Controller
                                    control={control}
                                    name="birth"
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={date => field.onChange(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Data de Nascimento"
                                            customInput={
                                                <Input ml="44px" id="birthDate" placeholder="Data de Nascimento" />
                                            }
                                        />
                                    )}
                                ></Controller>
                            </Flex>
                        </Flex>
                        <Flex gap="84px" color="gray">
                            <Input
                                id="allergies"
                                placeholder="Possui alergias?"
                                {...register('allergies', {
                                    required: 'This is required',
                                    minLength: { value: 3, message: 'Minimum length should be 3' },
                                })}
                            />
                            <Controller
                                control={control}
                                name="gender"
                                render={({ field }) => (
                                    <Select id="gender" placeholder="Sexo biológico" {...field}>
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                    </Select>
                                )}
                            ></Controller>
                        </Flex>
                        <Flex>
                            <Controller
                                name="weight"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mr="44px"
                                        id="weight"
                                        placeholder="Peso"
                                        value={formatNumber(String(field.value))}
                                        onChange={e => field.onChange(formatNumber(e.target.value))}
                                    />
                                )}
                            />
                            <Controller
                                name="height"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        ml="44px"
                                        id="height"
                                        placeholder="Altura"
                                        value={formatNumber(String(field.value))}
                                        onChange={e => field.onChange(formatNumber(e.target.value))}
                                    />
                                )}
                            />
                        </Flex>
                        <Flex>
                            <Input
                                mr="44px"
                                id="fiscalCode"
                                as={InputMask}
                                mask="999.999.999-99"
                                placeholder="CPF"
                                {...register('cpf', {
                                    required: 'This is required',
                                    minLength: { value: 4, message: 'Minimum length should be 4' },
                                })}
                            />
                            <Input
                                ml="44px"
                                id="phone"
                                as={InputMask}
                                mask="(99) 99999-9999"
                                placeholder="Celular"
                                {...register('phone', {
                                    required: 'This is required',
                                    minLength: { value: 4, message: 'Minimum length should be 4' },
                                })}
                            />
                        </Flex>
                    </Flex>
                    <Flex justifyContent="flex-end" mt="40px">
                        <Button w="306px" h="48px" mt="12px" type="submit">
                            Finalizar cadastro
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Flex>
    );
}
