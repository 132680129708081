import { BarChart, Bar, Rectangle, XAxis, YAxis, ResponsiveContainer, Cell } from 'recharts';
import { GetPainGraphModel } from '../models/painDiary.model';

const scalePainColors = {
    '1': '#8FE5F3',
    '2': '#16EAC2',
    '3': '#90D202',
    '4': '#D2E801',
    '5': '#FFF134',
    '6': '#FFC71E',
    '7': '#FB9A27',
    '8': '#FF8563',
    '9': '#FE4432',
    '10': '#FC0D00',
};

const data = [
    {
        name: '1',
        dias: 8,
    },
    {
        name: '2',
        dias: 7,
    },
    {
        name: '3',
        dias: 5,
    },
    {
        name: '4',
        dias: 4,
    },
    {
        name: '5',
        dias: 2,
    },
    {
        name: '6',
        dias: 4,
    },
    {
        name: '7',
        dias: 4,
    },
    {
        name: '8',
        dias: 4,
    },
    {
        name: '9',
        dias: 5,
    },
    {
        name: '10',
        dias: 2,
    },
    {
        name: '11',
        dias: 2,
    },
    {
        name: '12',
        dias: 5,
    },
    {
        name: '13',
        dias: 2,
    },
    {
        name: '14',
        dias: 2,
    },
    {
        name: '16',
        dias: 2,
    },
];

interface props {
    data: GetPainGraphModel[] | undefined;
    active: boolean;
}

export function PainChart({ data, active }: props) {
    if (!data || !active) {
        data = [];
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart width={500} height={300} data={data} margin={{ top: 6 }}>
                <XAxis dataKey="day" />
                <YAxis domain={[1, 10]} tickCount={10} interval={0} />
                <Bar
                    barSize={40}
                    radius={4}
                    dataKey="averagePain"
                    fill="#008080"
                    activeBar={<Rectangle fill="pink" stroke="blue" />}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            //@ts-ignore
                            fill={scalePainColors[entry.day]}
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
