import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Flex, Text } from '@chakra-ui/react';
import { GetPrescriptionModel } from '../models/medication.model';

interface props {
    data: GetPrescriptionModel[];
}

export default function PatientTable({ data }: props) {
    function formatDate(dataISO: Date | string) {
        const data = new Date(dataISO);
        const opcoes: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        };
        return data.toLocaleDateString('pt-BR', opcoes);
    }

    return (
        <Flex p="0px 44px" flexDir="column" h="80%" w="100%">
            <TableContainer w="100%" overflowY="auto">
                <Table variant="simple" size="lg">
                    <Thead position="sticky" top={0} zIndex={1} bgColor="#F7FAFC">
                        <Tr>
                            <Th>
                                <Text color="#2C7A7B" fontWeight="600" fontSize="12px">
                                    Data
                                </Text>
                            </Th>
                            <Th>
                                <Text color="#2C7A7B" fontWeight="600" fontSize="12px">
                                    Medicamento
                                </Text>
                            </Th>
                            <Th>
                                <Text color="#2C7A7B" fontWeight="600" fontSize="12px">
                                    Medida
                                </Text>
                            </Th>
                            <Th>
                                <Text color="#2C7A7B" fontWeight="600" fontSize="12px">
                                    Intervalo
                                </Text>
                            </Th>
                            <Th>
                                <Text color="#2C7A7B" fontWeight="600" fontSize="12px">
                                    Tempo de tratamento
                                </Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map(item => (
                            <>
                                {item.Posology.map(posology => (
                                    <Tr key={posology.id} color="#718096">
                                        <Td>{formatDate(item.initial_date)}</Td>
                                        <Td>{posology.medicine_name}</Td>
                                        <Td>{posology.dosage}</Td>
                                        <Td>{posology.interval} vezes</Td>
                                        <Td>{posology.daysTreatment} dias</Td>
                                    </Tr>
                                ))}
                            </>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );
}
