import { Flex, Text } from "@chakra-ui/react";
import { PainChartContainer } from "../components/PainChartContainer";

export function ChartsPage() {
  return (
    <Flex p="0px 44px" flexDir="column">
      <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600}>
        Gráficos
      </Text>
      <Flex w="100%" mt="24px">
        <PainChartContainer />
      </Flex>
    </Flex>
  );
}
