import { Flex, Text } from "@chakra-ui/react";
import { getInitialLettersName } from "../utils/getInitialLettersName";

interface Props {
  fullName: string;
  age: number;
}

export function PacienteCard({ fullName, age }: Props) {
  return (
    <Flex flexDir="row">
      <Flex
        justifyContent="center"
        alignItems="center"
        borderRadius={50}
        bgColor="#38B2AC"
        w="40px"
        h="40px"
      >
        <Text fontSize={16} fontWeight={600} color="white">
          {getInitialLettersName(fullName)}
        </Text>
      </Flex>
      <Flex ml="12px" flexDir="column">
        <Text fontWeight={500} fontSize={14} color="#2D3748">
          {fullName}
        </Text>
        <Text fontSize={14} fontWeight={400} color="#718096">
          {age} anos
        </Text>
      </Flex>
    </Flex>
  );
}
