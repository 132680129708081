import { useMutation } from '@tanstack/react-query';
import { api } from '../../service/api';
import { LoginModel, TokenModel } from '../../models/login.model';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from '../../context/AuthContext';

async function loginUser(credentials: LoginModel): Promise<AxiosResponse> {
    console.log(credentials);
    return api.post('/auth', credentials);
}
export function useLogin() {
    const toast = useToast();
    const { setToken } = useAuth();
    const login = useMutation<AxiosResponse, unknown, LoginModel, unknown>({
        mutationFn: loginUser,
        onSuccess: (response: AxiosResponse) => {
            const data: TokenModel = response.data;
            toast({
                title: 'Login realizado com sucesso!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
            setToken(data.token);
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.message ? error.response.data.message : 'Erro ao realizar login',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return login;
}
