export const intervalOptions = [
  { label: "01h", value: 1 },
  { label: "02h", value: 2 },
  { label: "03h", value: 3 },
  { label: "04h", value: 4 },
  { label: "05h", value: 5 },
  { label: "06h", value: 6 },
  { label: "07h", value: 7 },
  { label: "08h", value: 8 },
  { label: "09h", value: 9 },
  { label: "10h", value: 10 },
  { label: "11h", value: 11 },
  { label: "12h", value: 12 },
  { label: "13h", value: 13 },
  { label: "14h", value: 14 },
  { label: "15h", value: 15 },
  { label: "16h", value: 16 },
  { label: "17h", value: 17 },
  { label: "18h", value: 18 },
  { label: "19h", value: 19 },
  { label: "20h", value: 20 },
  { label: "21h", value: 21 },
  { label: "22h", value: 22 },
  { label: "23h", value: 23 },
  { label: "24h", value: 24 },
];

export const pushNotificationTimeStartOptions = [
  { label: "12h00", value: 12 },
  { label: "13h00", value: 13 },
  { label: "14h00", value: 14 },
  { label: "15h00", value: 15 },
  { label: "16h00", value: 16 },
  { label: "17h00", value: 17 },
  { label: "18h00", value: 18 },
  { label: "19h00", value: 19 },
  { label: "20h00", value: 20 },
  { label: "21h00", value: 21 },
  { label: "22h00", value: 22 },
  { label: "23h00", value: 23 },
  { label: "00h00", value: 24 },
  { label: "01h00", value: 1 },
  { label: "02h00", value: 2 },
  { label: "03h00", value: 3 },
  { label: "04h00", value: 4 },
  { label: "05h00", value: 5 },
  { label: "06h00", value: 6 },
  { label: "07h00", value: 7 },
  { label: "08h00", value: 8 },
  { label: "09h00", value: 9 },
  { label: "10h00", value: 10 },
  { label: "11h00", value: 11 },
];

export const proceduresOptions = [
  "Exodontia Simples",
  "Exodontia de elemento incluso",
  "Frenectomia",
  "Gengivoplastia",
  "Aumento de Coroa Clínica",
  "Enxerto gengival",
  "Implante Dentário Unitário",
  "Implante Dentário (Protocolo)",
  "Enxerto em Bloco autógeno Ramo",
  "Enxerto em Bloco autógeno Mento",
  "Osteonecrose Unilateral",
  "Osteonecrose Bilateral",
  "Tratamento endodôntico",
  "Bichectomia",
  "Lipoaspiração submentoniana",
  "Fio de sustentação",
  "Preenchimento labial",
  "DTM",
  "Medicação Profilática",
  "Medicação",
];
