import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Flex, Text } from '@chakra-ui/react';
import { useGetPatients } from '../hook/api/usePatient';
import { PacienteCard } from '../components/PacienteCard';
import { format } from 'date-fns';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { PatientModel } from '../models/patient.model';
import { useAuth } from '../context/AuthContext';
import { FilterModel } from '../models/filter.model';

export function PacientesPage() {
    const { userData } = useAuth!();
    const filter: FilterModel[] = [
        {
            name: 'dentistId',
            field: 'dentistId',
            filter: userData?.id!,
        },
    ];
    const getPatientsMutation = useGetPatients(filter);
    const navigate = useNavigate();

    const getAge = (birth: string) => {
        const today = new Date();
        const birthDate = new Date(birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    const formatedDate = (date: string) => {
        return format(new Date(date), 'dd/MM/yyyy');
    };

    const goToDetails = (patient: PatientModel) => {
        navigate(`/pacientes/detalhes`, { state: patient });
    };
    return (
        <Flex p="0px 44px" flexDir="column" w="80%" h="95%">
            <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600}>
                Meus pacientes
            </Text>
            <Flex w="100%" mt="44px" height="100%" flexDir="column" overflow="hidden">
                <TableContainer w="100%" overflowY="auto">
                    <Table>
                        <Thead bgColor="#F7FAFC" position="sticky" top={0} zIndex={1}>
                            <Tr>
                                <Th textTransform="none">Nome do paciente</Th>
                                <Th textTransform="none">Data do cadastro</Th>
                                <Th textTransform="none">Celular</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {getPatientsMutation.data &&
                                getPatientsMutation.data.data.map((patient: any) => (
                                    <Tr key={patient.id}>
                                        <Td>
                                            <PacienteCard age={getAge(patient.birth)} fullName={patient.name} />
                                        </Td>
                                        <Td>
                                            <Text fontWeight={400} fontSize={14}>
                                                {formatedDate(patient.created_at)}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={400} fontSize={14}>
                                                {patient.phone}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Flex color="#8C8A97" _hover={{ color: '#D3D3D3' }}>
                                                <FaExternalLinkAlt
                                                    cursor="pointer"
                                                    size={20}
                                                    onClick={() => goToDetails(patient)}
                                                />
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
        </Flex>
    );
}
