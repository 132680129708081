import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FiFilePlus, FiUserPlus, FiUsers, FiLink, FiBarChart2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { PainChartContainer } from '../components/PainChartContainer';
import { QuickAccessCard } from '../components/QuickAccessCard';
import { useAuth } from '../context/AuthContext';
import { useGetPatients } from '../hook/api/usePatient';
import { FilterModel } from '../models/filter.model';

export function HomePage() {
    const navigate = useNavigate();
    const { userData } = useAuth!();
    const filter: FilterModel[] = [
        {
            name: 'dentistId',
            field: 'dentistId',
            filter: userData?.id!,
        },
    ];
    const useGetPatientsMutation = useGetPatients(filter);

    return (
        <Flex p="0px 44px" alignItems="center" flexDir="column" h="100%" w="100%" maxW="1116px" m="0 auto">
            <Box w="100%" mt="40px">
                <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600}>
                    Olá, Dr. {userData?.name}
                </Text>
                <Text color="rgba(140, 138, 151, 1)" fontSize="14px" fontWeight={400}>
                    Tenha um excelente dia de trabalho
                </Text>
            </Box>

            <Flex mt="40px" flexDir="row" alignItems="center" w="100%">
                <Icon boxSize="16px" as={FiLink} color="rgba(122, 119, 134, 1)" />
                <Text color="#4E4B59" ml="12px" fontSize={16} fontWeight={700}>
                    Acesso Rápido
                </Text>
            </Flex>

            <Flex
                gap="32px"
                mt="16px"
                w={{
                    md: '100%',
                }}
                justifyContent="center"
                flexDir={{
                    sm: 'column',
                    lg: 'row',
                }}
                alignItems="center"
            >
                <QuickAccessCard
                    onClick={() => {
                        navigate('/pacientes/cadastrar');
                    }}
                    icon={<Icon boxSize="32px" as={FiUserPlus} color="rgba(49, 130, 206, 1)" />}
                    primaryColor="rgba(49, 130, 206, 1)"
                    secondaryColor="rgba(144, 205, 244, 1)"
                    subTitle="Cadastrar"
                    title="Novo paciente"
                />
                <QuickAccessCard
                    onClick={() => {
                        navigate('/pacientes');
                    }}
                    icon={<Icon boxSize="32px" as={FiUsers} color="rgba(229, 62, 62, 1)" />}
                    primaryColor="rgba(229, 62, 62, 1)"
                    secondaryColor="rgba(254, 178, 178, 1)"
                    subTitle={`Total de pacientes: ${useGetPatientsMutation.data?.data?.length}`}
                    title="Meus Pacientes"
                />
                <QuickAccessCard
                    onClick={() => {
                        navigate('/prescricoes/cadastrar');
                    }}
                    icon={<Icon boxSize="32px" as={FiFilePlus} color="rgba(39, 174, 96, 1)" />}
                    primaryColor="rgba(39, 174, 96, 1)"
                    secondaryColor="rgba(154, 230, 180, 1)"
                    subTitle="Adicionar prescrição"
                    title="Prescrições"
                />
            </Flex>
            <Flex mt="40px" flexDir="row" alignItems="center" w="100%">
                <Icon boxSize="20px" as={FiBarChart2} color="rgba(122, 119, 134, 1)" />
                <Text color="#4E4B59" ml="12px" fontSize={16} fontWeight={700}>
                    Gráfico - Escala Visual da Dor
                </Text>
            </Flex>
            <Box pb="100px" mt="16px" maxW="1116px" h="100%" w="100%" ml="16px">
                <PainChartContainer />
            </Box>
        </Flex>
    );
}
