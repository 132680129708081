import { Flex, Text, Box, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
  title: string;
  isClicked?: boolean;
  customIcon: IconType;
  onClick(): void;
}

export function SideNavItem({ title, isClicked, customIcon, onClick }: Props) {
  return (
    <Flex
      as="button"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
    >
      <Flex flexDir="row" alignItems="center" justifyContent="center">
        <Icon
          as={customIcon}
          boxSize={7}
          color={isClicked ? "#1a202c" : "#8C8A97"}
        />
        <Text
          color={isClicked ? "#1a202c" : "#8C8A97"}
          fontSize="16px"
          fontWeight={isClicked ? 700 : 500}
          ml="12px"
        >
          {title}
        </Text>
      </Flex>

      {isClicked ? <Box h="24px" w="4px" bgColor="#4E4B59" /> : null}
    </Flex>
  );
}
