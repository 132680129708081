import { Flex, Box, Text } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import imageBackground from '../assets/background/background.png';

export function StartLayout() {
    return (
        <Flex flexDir="column" h="100vh">
            <Box bgColor="teal" w="100%" h="80px" />
            <Flex
                h="100%"
                bgColor="#F9FCFE"
                flexDir="row"
                justifyContent="space-around"
                alignItems="center"
                bgImage={`url(${imageBackground})`}
                bgSize="cover"
            >
                <Flex flexDir="column" flex={0.5}>
                    <Text
                        fontWeight="600"
                        fontSize={{
                            md: '19px',
                            xl: '33px',
                            '2xl': '40px',
                        }}
                    >
                        Plataforma unificada para gestão de pacientes
                    </Text>
                    <Flex mt="32px" maxWidth="70%">
                        <Text
                            color="rgba(0, 0, 0, 0.48)"
                            fontWeight="500"
                            fontSize={{
                                md: '14px',
                                xl: '19px',
                                '2xl': '24px',
                            }}
                        >
                            Simplifique a gestão dos dados e tratamentos do seus pacientes
                        </Text>
                    </Flex>
                </Flex>
                <Outlet />
            </Flex>
        </Flex>
    );
}
