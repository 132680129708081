import { Box, Flex, Text } from '@chakra-ui/react';
import { Input } from '../components/Input';
import { LockIcon, EmailIcon } from '@chakra-ui/icons';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { LoginModel } from '../models/login.model';
import { useForm } from 'react-hook-form';
import { useLogin } from '../hook/api/useLogin';
import { useAuth } from '../context/AuthContext';
import { useEffect } from 'react';

export function LoginBox() {
    const navigate = useNavigate();
    const { isAuth } = useAuth();

    const { handleSubmit, register } = useForm<LoginModel>();

    const { mutate, isPending } = useLogin();

    async function handleLogin(values: LoginModel) {
        mutate(values);
    }

    useEffect(() => {
        if (isAuth) {
            navigate('/home');
        }
    }, [isAuth, navigate]);

    return (
        <Flex
            h="470px"
            bgColor="white"
            css={{ boxShadow: '0px 10px 50px 0px rgba(78, 75, 89, 0.10)' }}
            flexDir="column"
            style={{ maxWidth: 390 }}
            paddingY="32px"
            borderRadius="8px"
        >
            <form onSubmit={handleSubmit(handleLogin)}>
                <Flex flexDir="column" paddingX="32px">
                    <Text fontSize="20px" fontWeight="600">
                        Olá, boas vindas!
                    </Text>
                    <Text color="#7A7786" fontSize="18px" mt="14px">
                        Insira suas credenciais abaixo para acessar a plataforma
                    </Text>
                    <Box mt="20px">
                        <Input
                            inputType="email"
                            type="email"
                            {...register('email', { required: 'E-mail é obrigatório' })}
                            leftIcon={<EmailIcon color="gray.300" />}
                            placeholder="Seu e-mail"
                        />
                    </Box>
                    <Box mt="20px">
                        <Input
                            isSecureTextEntry
                            {...register('password', { required: 'Senha é obrigatória' })}
                            leftIcon={<LockIcon color="gray.300" />}
                            placeholder="Sua senha"
                        />
                    </Box>
                    <Button isLoading={isPending} type="submit" marginX="6px" mt="32px" loadingText="Entrando...">
                        Entrar
                    </Button>
                </Flex>
            </form>
            <Flex
                as="button"
                alignItems="center"
                justifyContent="center"
                bgColor="#F7FAFC"
                h="64px"
                mt="38px"
                onClick={() => {
                    navigate('/signup');
                }}
            >
                <Text fontSize="14px">Ainda não possui conta? </Text>
                <Text ml="4px" fontWeight="500" fontSize="16px">
                    Cadastre-se
                </Text>
            </Flex>
        </Flex>
    );
}
