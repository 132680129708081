import { Divider, Flex, Select, Text } from '@chakra-ui/react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import InputMask from 'react-input-mask';
import { intervalOptions, pushNotificationTimeStartOptions } from '../utils/constants';
import { useGetPatients } from '../hook/api/usePatient';
import { FilterModel } from '../models/filter.model';
import { CiSquarePlus } from 'react-icons/ci';
import { CreateMedicationModel, GetProcedureModel } from '../models/medication.model';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../context/AuthContext';
import { usePrescription } from '../hook/api/usePrescription';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetPatientModel } from '../models/patient.model';
import { useGetProcedureOptions } from '../hook/api/usePrescription';
import { useEffect, useState } from 'react';

export function CadastrarPrescricaoPage() {
    const filter: FilterModel[] = [
        {
            name: 'order',
            field: 'order',
            filter: 'asc',
        },
        {
            name: 'orderby',
            field: 'orderby',
            filter: 'name',
        },
    ];
    const [procedureOptions, setProcedureOptions] = useState<GetProcedureModel[]>([]);

    const getPatientsMutation = useGetPatients(filter);
    const getProcedureOptionsMutation = useGetProcedureOptions();
    useEffect(() => {
        if (getProcedureOptionsMutation.isSuccess && getProcedureOptionsMutation.data) {
            setProcedureOptions(getProcedureOptionsMutation.data.data);
        }
    }, [getProcedureOptionsMutation.isSuccess, getProcedureOptionsMutation.data]);
    const location = useLocation();
    const navigate = useNavigate();
    const { userData } = useAuth();
    const { mutate, isSuccess } = usePrescription();
    const patient: GetPatientModel = location.state || {};

    const { register, control, handleSubmit } = useForm<CreateMedicationModel>({
        defaultValues: {
            patientId: patient.id,
            initial_date: new Date(),
            medications: [
                {
                    medicineName: '',
                    dosage: '',
                    daysTreatment: '',
                    intervalDosage: '',
                    pushNotificationStartTime: '',
                },
            ],
        },
    });
    const { fields, append } = useFieldArray({
        control,
        name: 'medications',
    });

    function onSubmit(values: CreateMedicationModel) {
        values.doctorId = userData?.id;
        mutate(values);
    }
    if (isSuccess) {
        navigate('/home');
    }

    return (
        <Flex p="0px 44px" pb="10px" flexDir="column" h="100%" overflow="hidden">
            <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600} h="25px">
                Cadastrar Prescrição
            </Text>
            <Flex
                mt="24px"
                borderWidth="0.5px"
                borderColor="#E2E8F0"
                borderRadius={16}
                maxWidth="1100px"
                css={{ boxShadow: '0px 1px 2px 0.5px rgba(78, 75, 89, 0.10)' }}
                w="100%"
                flexDir="column"
                p="32px"
                bgColor="#F7FAFC"
                h="100%"
                maxH="700px"
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                    <Flex flexDir="column">
                        <Text color="#2C7A7B" fontSize="20px" fontWeight={600}>
                            Prescrição do Paciente
                        </Text>
                        <Text color="#8C8A97" fontSize="14px" fontWeight={400}>
                            Prescrição de medicamentos para o paciente
                        </Text>
                    </Flex>
                    <Flex mt="44px" gap="40px" flexDir="column" rowGap="20px" h="100%" overflowY="hidden">
                        <Flex gap="22px">
                            <Select
                                id="patientName"
                                placeholder="Paciente"
                                bgColor="#fff"
                                {...register('patientId', { required: true })}
                            >
                                {getPatientsMutation.data?.data.map((patient: any) => (
                                    <option key={patient.id} value={patient.id}>
                                        {patient.name}
                                    </option>
                                ))}
                            </Select>
                            <Select
                                bgColor="#fff"
                                id="procedure_id"
                                placeholder="Selecione o procedimento"
                                {...register('procedure_id', { required: true })}
                            >
                                {procedureOptions.map(option => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Select>

                            <Flex width="100%">
                                <Controller
                                    control={control}
                                    name={`initial_date`}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={date => field.onChange(date)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Data de Nascimento"
                                            customInput={
                                                <Input id="birthDate" placeholder="Data de Nascimento" bgColor="#fff" />
                                            }
                                        />
                                    )}
                                ></Controller>
                            </Flex>
                        </Flex>
                        <Text color="#2C7A7B" fontSize="15px" fontWeight={600}>
                            Posologia
                        </Text>
                        <Flex h="100%" w="100%" flexDirection="column" overflowY="auto" gap="40px" padding="5px">
                            {fields.map((field, index) => (
                                <Flex key={field.id} w="100%" gap="40px" flexDirection="column">
                                    <Flex gap="40px">
                                        <Input
                                            bgColor="#fff"
                                            id="medicamento"
                                            placeholder="Medicamento"
                                            {...register(`medications.${index}.medicineName`, { required: true })}
                                        />

                                        <Input
                                            bgColor="#fff"
                                            id="dosagem"
                                            placeholder="dosagem"
                                            {...register(`medications.${index}.dosage`, { required: true })}
                                        />
                                    </Flex>
                                    <Flex gap="24px">
                                        <Input
                                            bgColor="#fff"
                                            id="numberDaysTreatment"
                                            placeholder="Quantidade de dias"
                                            as={InputMask}
                                            maskChar={null}
                                            mask="999"
                                            {...register(`medications.${index}.daysTreatment`, { required: true })}
                                        />
                                        <Select
                                            bgColor="#fff"
                                            id="intervalDosage"
                                            placeholder="Selecione o intervalo"
                                            {...register(`medications.${index}.intervalDosage`, { required: true })}
                                        >
                                            {intervalOptions.map(option => (
                                                <option key={option.label} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                        <Select
                                            bgColor="#fff"
                                            id="pushNotificationStartTime"
                                            placeholder="Início dos alertas"
                                            {...register(`medications.${index}.pushNotificationStartTime`, {
                                                required: true,
                                            })}
                                        >
                                            {pushNotificationTimeStartOptions.map(option => (
                                                <option key={option.label} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Select>
                                    </Flex>
                                    <Divider borderColor="#E2E8F0" />
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                    <Flex alignContent="flex-end" flexDirection="column" gap="10px">
                        <Flex
                            gap="3px"
                            alignItems="center"
                            cursor="pointer"
                            width="250px"
                            onClick={() =>
                                append({
                                    medicineName: '',
                                    dosage: '',
                                    daysTreatment: '',
                                    intervalDosage: '',
                                    pushNotificationStartTime: '',
                                })
                            }
                        >
                            <CiSquarePlus size={35} color="#2C7A7B" />
                            <Text color="#2C7A7B">Adicionar nova medicação</Text>
                        </Flex>
                        <Button w="306px" h="48px" mt="12px" type="submit" disabled={getPatientsMutation.isLoading}>
                            Finalizar prescrição
                        </Button>
                    </Flex>
                </form>
            </Flex>
        </Flex>
    );
}
