import { Flex, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterModel } from '../models/filter.model';
import { GetPatientModel } from '../models/patient.model';
import { useGetPrescription } from '../hook/api/usePrescription';
import { useEffect, useState } from 'react';
import { GetPrescriptionModel } from '../models/medication.model';
import { Button } from '../components/Button';
import { FaPlus } from 'react-icons/fa6';
import PatientTable from '../components/patientTable';

export function PatientDetailsPage() {
    const [prescriptions, setPrescriptions] = useState<GetPrescriptionModel[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const patient: GetPatientModel = location.state || null;

    useEffect(() => {
        if (!patient) {
            navigate('/pacientes');
        }
    }, [navigate, patient]);

    const goToCreatePrescription = () => {
        navigate('/prescricoes/cadastrar', { state: patient });
    };

    const filter: FilterModel[] = [
        {
            name: 'patient_id',
            field: 'patient_id',
            filter: patient ? patient.id : 0,
        },
    ];
    const getPrescriptionMutation = useGetPrescription(filter);
    useEffect(() => {
        if (getPrescriptionMutation.isSuccess && getPrescriptionMutation.data) {
            setPrescriptions(getPrescriptionMutation.data.data);
        }
    }, [getPrescriptionMutation.isSuccess, getPrescriptionMutation.data]);

    const getAge = (birth: string) => {
        const today = new Date();
        const birthDate = new Date(birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    if (!patient) {
        return (
            <Flex flexDir="column" alignItems="center" justifyContent="center" h="100vh">
                <Text fontSize="24px" fontWeight={500} color="#2D3748">
                    Selecione um paciente
                </Text>
            </Flex>
        );
    }

    return (
        <Flex p="0px 44px" flexDir="column" h="100%" w="80%" overflow="hidden">
            <Text color="rgba(51, 48, 62, 1)" fontSize="24px" fontWeight={600} h="15px">
                Ficha do Paciente
            </Text>

            <Flex
                padding="20px"
                flexDir="column"
                w="100%"
                border="0.5px solid #38B2AC"
                borderRadius="8px"
                mt="44px"
                h="140px"
                css={{ boxShadow: '0px 5px 5px 0.5px rgba(78, 75, 89, 0.10)' }}
            >
                <Flex width="100%" flexDir="row" alignItems="center" w="100%">
                    <Flex rounded="full" bg="#38B2AC" h="90px" w="100px" alignItems="center" justifyContent="center">
                        <Text fontSize="35px" fontWeight={600} color="white" p="10px">
                            {patient.name.split(' ')[0]?.charAt(0)} {patient.name.split(' ')[1]?.charAt(0)}
                        </Text>
                    </Flex>

                    <Flex flexDirection="column" h="100%" pt="10px" pl="20px" gap="5px" w="100%">
                        <Text color="#2D3748" size="24px" fontWeight="500">
                            {patient.name}
                        </Text>
                        <Flex w="100%" flexDir="row" gap="10px" justify="space-between">
                            <Flex flexDir="column" gap="8px">
                                <Flex flexDir="row" gap="10px">
                                    <Text color="#319795" fontWeight="500" size="16px" w="45px">
                                        Idade
                                    </Text>
                                    <Text color="#718096" fontWeight="400" size="16px">
                                        {getAge(patient.birth)} anos
                                    </Text>
                                </Flex>

                                <Flex flexDir="row" gap="10px">
                                    <Text color="#319795" fontWeight="500" size="16px" w="45px">
                                        CPF
                                    </Text>
                                    <Text color="#718096" fontWeight="400" size="16px">
                                        {patient.cpf}
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex flexDir="column" gap="8px">
                                <Flex flexDir="row" gap="10px">
                                    <Text color="#319795" fontWeight="500" size="16px" w="45px">
                                        Peso
                                    </Text>
                                    <Text color="#718096" fontWeight="400" size="16px">
                                        {patient.weight} Kg
                                    </Text>
                                </Flex>

                                <Flex flexDir="row" gap="10px">
                                    <Text color="#319795" fontWeight="500" size="16px" w="45px">
                                        Sexo
                                    </Text>
                                    <Text color="#718096" fontWeight="400" size="16px">
                                        {patient.gender === 'M' ? 'Masculino' : 'Feminino'}
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex flexDir="column" gap="8px">
                                <Flex flexDir="row" gap="10px">
                                    <Text color="#319795" fontWeight="500" size="16px" w="65px">
                                        Alergias
                                    </Text>
                                    <Text color="#718096" fontWeight="400" size="16px">
                                        {patient.allergies}
                                    </Text>
                                </Flex>

                                <Flex flexDir="row" gap="10px"></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex w="100%" borderBottom="2px solid #319795" mt="20px" mb="20px" justify="center" h="40px">
                <Text color="#319795" fontSize="18px" fontWeight="700" py="8px">
                    Prescrições
                </Text>
            </Flex>

            <Flex
                flexDir="column"
                flex={1}
                bg="#F7FAFC"
                borderRadius="8px"
                p="20px"
                overflowY="hidden"
                marginBottom="20px"
            >
                <Text fontWeight="500" fontSize="12px" color="#8C8A97">
                    Prescrições cadastradas
                </Text>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="20px">
                    <Text color="#2D3748" fontWeight="500" fontSize="20px">
                        {prescriptions.length} Prescrições
                    </Text>
                    <Button height="40px" onClick={goToCreatePrescription}>
                        <FaPlus size={22} />
                        <Text ml="12px">Adicionar novo</Text>
                    </Button>
                </Flex>

                <Flex width="100%" h="100%" flexDir="column" mt="20px">
                    <PatientTable data={prescriptions} />
                </Flex>
            </Flex>
        </Flex>
    );
}
