import InputMask from 'react-input-mask';
import { Flex, Text, useToast } from '@chakra-ui/react';
import { Input } from '../components/Input';
import { LockIcon, EmailIcon, ArrowBackIcon, Icon } from '@chakra-ui/icons';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaIdBadge } from 'react-icons/fa6';
import { useCreateUser } from '../hook/api/useUser';
import { useForm } from 'react-hook-form';
import { SignUpFormModel } from '../models/user.model';
import { useState } from 'react';

export function SignUpBox() {
    const toast = useToast();
    const { mutate, isSuccess, isPending } = useCreateUser();
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const navigate = useNavigate();

    if (isSuccess) {
        navigate('/');
    }
    const { register, handleSubmit } = useForm<SignUpFormModel>();

    async function handleCreateUser(values: SignUpFormModel) {
        if (values.password !== confirmPassword) {
            toast({
                title: 'Erro',
                description: 'As senhas não coincidem',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            return;
        }
        mutate(values);
    }

    return (
        <Flex
            h="470px"
            bgColor="white"
            css={{ boxShadow: '0px 10px 50px 0px rgba(78, 75, 89, 0.10)' }}
            flexDir="column"
            style={{ maxWidth: 390 }}
            paddingY="32px"
            borderRadius="8px"
        >
            <Flex
                onClick={() => {
                    navigate(-1);
                }}
                as="button"
                paddingX="32px"
            >
                <ArrowBackIcon boxSize={6} color="black" />
                <Text fontWeight={500} ml="10px">
                    Voltar para o login
                </Text>
            </Flex>
            <form onSubmit={handleSubmit(handleCreateUser)} style={{ flex: 1 }}>
                <Flex mt="20px" flexDir="column" paddingX="32px" gap="20px">
                    <Input
                        {...register('name')}
                        leftIcon={<Icon as={FaUser} color="gray.300" />}
                        placeholder="Seu nome completo"
                    />
                    <Input
                        {...register('cro')}
                        as={InputMask}
                        maskChar={null}
                        mask="999999"
                        leftIcon={<Icon as={FaIdBadge} color="gray.300" />}
                        placeholder="Seu CRO"
                    />
                    <Input
                        {...register('email')}
                        inputType="email"
                        leftIcon={<EmailIcon color="gray.300" />}
                        placeholder="Seu e-mail"
                    />
                    <Input
                        {...register('password')}
                        isSecureTextEntry
                        leftIcon={<LockIcon color="gray.300" />}
                        placeholder="Sua senha"
                    />
                    <Input
                        isSecureTextEntry
                        leftIcon={<LockIcon color="gray.300" />}
                        placeholder="Confirme sua senha"
                        onChange={e => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                    />
                    <Button marginX="6px" mt="12px" type="submit" isLoading={isPending} loadingText="Cadastrando...">
                        Concluir cadastro
                    </Button>
                </Flex>
            </form>
        </Flex>
    );
}
