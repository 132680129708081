import { useState, HTMLInputTypeAttribute } from "react";
import {
  FormControl,
  FormErrorMessage,
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { Button } from "./Button";
import React from "react";

interface Props extends InputProps {
  leftIcon?: JSX.Element;
  placeholder: string;
  isSecureTextEntry?: boolean;
  inputType?: HTMLInputTypeAttribute;
  errorMessage?: string;
  maskChar?: null | string;
  mask?: null | string;
}

export const Input = React.forwardRef((props: Props, ref) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { leftIcon, placeholder } = props;
  const inputTypeDefault = props?.inputType || "text";

  return (
    <FormControl isInvalid={Boolean(props?.errorMessage?.length)}>
      <InputGroup>
        {leftIcon ? (
          <InputLeftElement pointerEvents="none">{leftIcon}</InputLeftElement>
        ) : null}
        <InputChakra
          {...props}
          pr={props?.isSecureTextEntry ? "84px" : undefined}
          type={
            props?.isSecureTextEntry
              ? show
                ? inputTypeDefault
                : "password"
              : "text"
          }
          placeholder={placeholder}
          ref={ref}
        />
        {props?.isSecureTextEntry ? (
          <InputRightElement mr="8px" width="68px">
            <Button
              colorScheme={undefined}
              h="1.75rem"
              size="sm"
              onClick={handleClick}
            >
              {show ? "Ocultar" : "Mostrar"}
            </Button>
          </InputRightElement>
        ) : null}
      </InputGroup>
      {props?.errorMessage ? (
        <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
});
