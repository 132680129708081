import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import Cookies from 'js-cookie'; // Importe js-cookie em vez de react-cookie
import { TokenModel, GetUserModel } from '../models/user.model';
import { userService } from '../service/user';
import { useJwt } from 'react-jwt';
import { FilterModel } from '../models/filter.model';

interface AuthContextType {
    token: string | null;
    setToken: (token: string) => void;
    isAuth: boolean;
    logout: () => void;
    userData: GetUserModel | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setTokenState] = useState<string | null>(Cookies.get('auth-token') || null);
    const [userData, setUserData] = useState<GetUserModel | null>(null);
    const { decodedToken } = useJwt<TokenModel>(token || '');

    const setToken = (token: string) => {
        Cookies.set('auth-token', token, { path: '/', expires: 18 });
        setTokenState(token);
    };

    const logout = useCallback(() => {
        Cookies.remove('auth-token', { path: '/' });
        setTokenState(null);
    }, []);

    const isAuth = !!token;

    useEffect(() => {
        if (token) {
            if (!decodedToken) {
                return;
            }
            console.log('Token válido', decodedToken);
            const filter: FilterModel = {
                name: 'FirebaseUid',
                field: 'firebaseUid',
                filter: decodedToken!.user_id,
            };
            userService
                .getUsers([filter])
                .then(users => {
                    if (users.length === 0) {
                        logout();
                        return;
                    }
                    setUserData(users[0]);
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            logout();
        }
    }, [decodedToken, logout, token]);

    return (
        <AuthContext.Provider value={{ token, setToken, isAuth, logout, userData }}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
