import axios from 'axios';
import Cookies from 'js-cookie';

const apiHost = process.env.REACT_APP_API_HOST;

export const api = axios.create({
    // baseURL: 'https://mdhqazejdp.us-east-2.awsapprunner.com',
    baseURL: 'http://localhost:3333',
});

api.interceptors.request.use(
    config => {
        const token = Cookies.get('auth-token'); // 'jwt-token' é o nome do cookie onde o JWT é armazenado
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
