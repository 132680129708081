import { Flex, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { ProfileArea } from '../components/ProfileArea';
import { SideNav } from '../components/SideNav';
import { useAuth } from '../context/AuthContext';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from '@choc-ui/chakra-autocomplete';
import { FaSearch } from 'react-icons/fa';
import { useGetPatients } from '../hook/api/usePatient';
import { PatientModel } from '../models/patient.model';

export function ProtectedLayout() {
    const getPatientsMutation = useGetPatients();
    const navigate = useNavigate();
    const { isAuth, logout, userData } = useAuth();
    if (!isAuth) {
        return <Navigate to="/" />;
    }

    const goToDetails = (patient: PatientModel) => {
        navigate(`/pacientes/detalhes`, { state: patient });
    };

    return (
        <Flex flexDir="row" h="100vh" w="100vw" css={{ boxSizing: 'border-box' }} overflow="hidden">
            <SideNav logout={logout} />

            <Flex
                flexDir="column"
                h="100vh"
                flex={1}
                overflow="hidden"
                css={{ boxSizing: 'border-box' }}
                w="calc(100vw - 256px)"
                alignItems="center"
            >
                <Flex justifyContent="space-between" w="100%" h="60px" paddingX="42px">
                    <Flex alignItems="center" w="70%">
                        <AutoComplete emptyState={<AutoCompleteItem value="Nenhum paciente encontrado" />}>
                            <InputGroup>
                                <AutoCompleteInput
                                    placeholder="pesquisar por nome"
                                    variant="filled"
                                    backgroundColor="white"
                                    borderRadius="8px"
                                    border=" 1px solid #E1E0E7"
                                />

                                <InputLeftElement>
                                    <FaSearch color="#A0A0B2" />
                                </InputLeftElement>
                            </InputGroup>
                            <AutoCompleteList>
                                {getPatientsMutation.data &&
                                    getPatientsMutation.data.data.map((patient: any) => (
                                        <AutoCompleteItem
                                            key={patient.id}
                                            value={patient.name}
                                            onClick={() => goToDetails(patient)}
                                        />
                                    ))}
                            </AutoCompleteList>
                        </AutoComplete>
                    </Flex>
                    <ProfileArea name={userData?.name} cro={userData?.cro} />
                </Flex>
                <Flex
                    flexDir="column"
                    h="calc(100vh - 60px)"
                    overflowY="auto"
                    alignItems="center"
                    css={{ boxSizing: 'border-box' }}
                    w="100%"
                >
                    <Outlet />
                </Flex>
            </Flex>
        </Flex>
    );
}
