export function getInitialLettersName(fullName: string) {
  let names = fullName.split(" ");
  if (names.length === 1) {
    return names[0].slice(0, 2).toUpperCase();
  } else {
    return (
      names[0].charAt(0).toUpperCase() +
      names[names.length - 1].charAt(0).toUpperCase()
    );
  }
}
