import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/Router';
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import '@fontsource-variable/inter';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './service/queryClient';
import { AuthProvider } from './context/AuthContext';

const theme = extendTheme({
    fonts: {
        heading: `'Inter Variable', sans-serif`,
        body: `'Inter Variable', sans-serif`,
    },
});

function App() {
    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
            </QueryClientProvider>
        </AuthProvider>
    );
}

export default App;
