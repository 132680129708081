import { PropsWithChildren } from "react";
import { Button as ButtonChakra, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {}

export function Button(props: PropsWithChildren<Props>) {
  const { children } = props;

  return (
    <ButtonChakra h="54px" colorScheme={props.colorScheme || "teal"} {...props}>
      {children}
    </ButtonChakra>
  );
}
