import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '../../service/api';
import { PatientModel } from '../../models/patient.model';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/react';
import { FilterModel } from '../../models/filter.model';
import { useEffect } from 'react';

async function createPatient(patient: PatientModel): Promise<AxiosResponse> {
    return api.post('/patient', patient);
}

async function getPatients(params: any): Promise<AxiosResponse> {
    return api.get('/patient', { params });
}

export function useCreatePatient() {
    const toast = useToast();
    const createPatientMutation = useMutation<AxiosResponse, unknown, PatientModel, unknown>({
        mutationFn: createPatient,
        onSuccess: () => {
            toast({
                title: 'Paciente criado com sucesso!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        },
        onError: (error: any) => {
            toast({
                title: error.response.data.message ? error.response.data.message : 'Erro ao criar paciente',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return createPatientMutation;
}

export function useGetPatients(filters?: FilterModel[]) {
    let params = {};
    if (filters) {
        filters.map((f: any) => {
            let fil: any = {};
            fil[f.field] = f.filter;
            params = { ...params, ...fil };
            return null;
        });
    }
    const toast = useToast();
    const getPatientsMutation = useQuery({
        queryFn: () => getPatients(params),
        queryKey: ['patients', params],
    });

    useEffect(() => {
        if (getPatientsMutation.isError) {
            toast({
                title: 'Erro ao buscar pacientes',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    });

    return getPatientsMutation;
}
